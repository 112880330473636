import React from "react"
import PropTypes from "prop-types"
import styles from "./LinkList.module.css"
import Text from "../Text/Text"
import { Link } from "gatsby"

export default function LinkList({ items }) {
  return (
    <ul className={styles.list}>
      {items.map(({ label, to }) => (
        <li key={to} className={styles.listItem}>
          <Link className={styles.link} activeClassName={styles.active} to={to}>
            <Text inline semispread uppercase>
              {label}
            </Text>
          </Link>
        </li>
      ))}
    </ul>
  )
}

LinkList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ).isRequired,
}
