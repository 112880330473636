import React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts/Layout/Layout"
import ProjectList from "../components/ProjectList/ProjectList"
import Section from "../components/Section/Section"

import { tagPath } from "../utils/paths"
import LinkList from "../components/LinkList/LinkList"

export default function ProjectsPage({ data, pageContext: { filter } }) {
  const { tags, projects } = data.datoCmsProjectList
  const validProjects = !filter
    ? projects
    : projects.filter(project =>
        project.tags.some(({ slug }) => slug === filter)
      )

  return (
    <Layout seoTitle="Projetos" footerDivider>
      <LinkList
        items={[{ label: "Todos", to: "/projetos" }].concat(
          tags.map(({ slug, title }) => ({
            label: title,
            to: tagPath(slug),
          }))
        )}
      />

      <Section>
        <ProjectList items={validProjects} />
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    datoCmsProjectList {
      tags {
        title
        slug
      }
      projects {
        date(formatString: "YYYY")
        code
        title
        subtitleHighlight
        subtitle
        url
        cover {
          fluid(maxWidth: 960, imgixParams: { fm: "webp", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        tags {
          slug
          title
        }
      }
    }
  }
`
